export const recaptchaInit = (token) => {

    return new Promise((resolve, reject) => {
        let script = document.getElementById('recaptcha-script')
        const $script = script != null ? script : document.createElement('script')
        $script.id = "recaptcha-script";
        $script.src = 'https://www.google.com/recaptcha/api.js?render=' + token
        resolve(document.head.appendChild($script));

        setTimeout(() => reject(new Error("Google reCaptcha")), 3000);
    });
}



export const recaptchaSend = (token, action, next) => {
    return setTimeout(() => {
        return grecaptcha.ready(() => {// eslint-disable-line
            return grecaptcha.execute(token, { action: action }).then((token) => {// eslint-disable-line
                next(token)
            })
        });
    }, 1000)
}
export const recaptchaDestroy = () => {
    let script = document.getElementById('recaptcha-script')
    script != null ? script.remove() : false
}


