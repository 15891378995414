<template>
    <PublicLayout>
        <v-card
            color="primary"
            elevation="1"
            class="text-body-2 pa-2 mx-auto"
            rounded="xl"
            width="max-content"
        >
            <v-card-title><h3 class="mx-auto">Contact us</h3></v-card-title>
            <v-card-text>
                <div
                    v-if="success"
                    class="d-flex justify-center flex-column mt-10"
                >
                    <v-icon large color="success"
                        >mdi-check-circle-outline</v-icon
                    >
                    <p class="dark--text text-center mt-2 font-weight-bold">
                        Thank you for your message!
                    </p>
                </div>
                <v-form v-else-if="feedback" ref="form" v-model="valid">
                    <v-row>
                        <v-col>
                            <CustomInput
                                label="Name*"
                                v-model="feedback.name"
                                :rules="[(v) => !!v || 'Name is required']"
                                required
                                :error="errors.name"
                                @change="errors.name = ''"
                            />
                        </v-col>

                        <v-col>
                            <CustomInput
                                label="Email*"
                                type="email"
                                v-model="feedback.email"
                                :rules="[rules.required, rules.email]"
                                required
                                :error="errors.email"
                                @change="errors.email = ''"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <CustomInput
                                label="Message"
                                type="textarea"
                                v-model="feedback.message"
                                :error="errors.message"
                                @change="errors.message = ''"
                            />
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <div id="recaptcha-container"></div>
                    </v-row>
                    <v-row justify="center">
                        <GradientButton @click="handleSubmit">
                            Send
                        </GradientButton>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </PublicLayout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
    recaptchaInit,
    recaptchaSend,
    recaptchaDestroy,
} from "../utils/recapthca";
export default {
    name: "ContactUs",
    components: {
        PublicLayout: () => import("@/layouts/public/Index.vue"),
    },
    data() {
        return {
            valid: true,
        };
    },
    computed: {
        ...mapGetters({
            success: "feedback/success",
            feedback: "feedback/item",
            errors: "feedback/errors",
            recaptchaToken: "recaptchaToken",
        }),
    },
    methods: {
        ...mapActions({
            storeFeedback: "feedback/storeItem",
            setDefaultFeedback: "feedback/setDefaultItem",
        }),
        async handleSubmit() {
            this.$refs.form.validate();
            if (this.valid) {
                recaptchaInit(this.recaptchaToken).then(() => {
                    recaptchaSend(
                        this.recaptchaToken,
                        "contact_us",
                        this.storeFeedback
                    );
                });
            }
        },
    },
    created() {
        this.setDefaultFeedback();
        this.rules = {
            required: (value) => !!value || "Email is required",
            email: (value) => {
                const pattern =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || "Invalid e-mail.";
            },
        };
    },
    beforeDestroy() {
        recaptchaDestroy();
    },
};
</script>

<style lang="scss" scoped>
</style>
